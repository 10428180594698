.marker {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  background: var(--secondary);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.marker::after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: var(--primary);
  position: absolute;
  border-radius: 50%;
}
