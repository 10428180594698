.circle {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-success {
  background: #28a745;
}

.circle-warning {
  background: #ffc107;
}

.circle-text {
  font-size: 1em;
  font-weight: 700;
  color: #fff;
}

.text-dark {
  color: #000
}