.map-container {
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 10px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}
