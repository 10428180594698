.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
  outline: none;
}

.navbar-dark .navbar-toggler {
  color: var(--neutral);
  border: none;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--neutral);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: var(--neutral);
}

@media screen and (max-width: 990px) {
  nav.navbar .navbar-nav .nav-item {
    display: block;
    text-align: center;
  }
}
