.display-img-container {
  height: 100px;
  width: 100%;
  margin: 10px 0;
  padding: 10px;

  background: #efefef;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display-img-container p.select-image-text {
  font-size: 1.1rem;
  font-weight: 400;
}

.display-img-container .image-input-box {
  width: 80px;
  height: 100%;
}
