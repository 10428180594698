:root {
  --primary: #4d4845;
  --secondary: #fc9c73;
  --neutral: #ffffff;
}

body {
  background: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-primary {
  background: var(--primary) !important;
}

.bg-secondary {
  background: var(--secondary) !important;
}

.bg-neutral {
  background: var(--neutral) !important;
}

.primary {
  color: var(--primary) !important;
}

.secondary {
  color: var(--secondary) !important;
}

.neutral {
  color: var(--neutral) !important;
}

/* Container */
.container {
  background: var(--neutral);
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px !important;
}

/* Forms */
.form-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
select[readonly], input[readonly] {
  pointer-events: none;
  touch-action: none;
  appearance: none;
}

.form-control {
  background: #e0e0e0 !important;
  border: #dedede !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 156, 115, 0.5);
}

/* Buttons */
.btn-primary {
  background: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: var(--neutral) !important;
}

/* Tables */
.table .thead-dark {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.table .thead-dark th {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.table .thead-dark small.text-muted {
  color: var(--secondary) !important;
}

/* Pagination */
ul.pagination li.page-item .page-link {
  color: var(--primary) !important;
}

ul.pagination li.page-item.active .page-link {
  color: #ffffff !important;
  background-color: var(--primary);
  border-color: var(--primary);
}

ul.pagination li.page-item.disabled .page-link {
  color: #6c757d !important;
}

ul.pagination li.page-item .page-link:hover {
  color: #383432;
}

ul.pagination li.page-item .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 72, 69, 0.5);
}

/* Accordion card */
.accordion .card .card-header {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--neutral);
}

/* loading spinner background */
.loading-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 1;
  background-color: #000;
  opacity: 0.3;
}

.spinner-bg {
  position: relative;
  left: -50%;
}

.container {
  margin: 80px auto 0 auto;
  max-width: 1200px;
}

/* border */
.border-3 {
  border: 3px solid;
}

.flex-1 {
  flex: 1;
}

/* Pills */
aside.nav-pills a.nav-link {
  color: var(--primary);
}

aside.nav-pills a.nav-link.active {
  background: var(--primary);
}

aside.nav-pills a.nav-link:hover:not(.active) {
  color: #38332e;
}

@media only screen and (max-width: 800px) {
  #flip-scroll .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
  #flip-scroll * html .cf {
    zoom: 1;
  }
  #flip-scroll *:first-child + html .cf {
    zoom: 1;
  }

  #flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  #flip-scroll th,
  #flip-scroll td {
    margin: 0;
    vertical-align: top;
  }
  #flip-scroll th {
    text-align: left;
  }

  #flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }
  #flip-scroll thead {
    display: block;
    float: left;
  }
  #flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  #flip-scroll thead tr {
    display: block;
  }
  #flip-scroll th {
    display: block;
    text-align: right;
  }
  #flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  #flip-scroll td {
    display: block;
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  #card table {
    border: 0;
  }

  #card table thead {
    display: none;
  }

  #card table tr {
    margin-bottom: 20px;
    display: block;
    border-bottom: 2px solid #ddd;
    box-shadow: 2px 2px 1px #dadada;
  }

  #card table td {
    padding: 10px;
    display: block;
    text-align: right !important;
    font-size: 13px;
  }

  #card table td:last-child {
    border-bottom: 0;
  }

  #card table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }
  #card tbody {
    /* line-height: 0 !important; */
  }
}
