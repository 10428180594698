.background-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 1;
  background-color: #000;
  opacity: 0.3;
}