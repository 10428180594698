.mainSlide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 100px;
}

.button1 {
  position: absolute;
  color: white;
  left: 0px;
  font-size: 70px;
  z-index: 1;
}

.button1:hover {
  color: #337AB7;
}

.button2 {
  position: absolute;
  color: white;
  right: 0px;
  font-size: 70px;
  z-index: 1;
}

.button2:hover {
  color: #337AB7;
}

.slides {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.slide {
  border: 4px solid transparent;
  
}

.slide:hover {
  border: 4px solid #337AB7;
}
