.scroll-container .scroll-container-card {
  flex-grow: 1;
  flex-shrink: 0;

  max-width: 130px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.scroll-container .scroll-container-card input {
  font-size: 12px;
}

.scroll-container .scroll-container-card input.form-control.ean {
  background: #5c97bf !important;
  color: #fff;
}

.scroll-container .scroll-container-card input.form-control.artigo {
  background: #446cb3 !important;
  color: #fff;
}

.scroll-container .scroll-container-card img.item-img {
  max-width: 130px;
}

.scroll-container .scroll-container-card .empty-item {
  width: 130px;
  height: 100%;
  min-height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-weight: bold;
}

.scroll-container .scroll-container-card.empty {
  border: 1px solid #000;
  background: #c5c5c5;
  margin-left: 0;
}
