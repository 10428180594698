.visita-page-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "imagem  visita_box"
    "imagem ponto_de_venda_box";
}

.visita-page-container .visita-imagem {
  grid-area: imagem;
}

.visita-page-container .visita-box {
  grid-area: visita_box;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.visita-page-container .ponto-de-venda-box {
  grid-area: ponto_de_venda_box;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.hidden-image-container {
  display: none;
}

@media (max-width: 500px) {
  .visita-page-container {
    grid-template-areas:
      "visita_box ponto_de_venda_box"
      "imagem imagem";
  }
}

@media print {
  .visita-page-container {
    grid-template-areas:
      "visita_box ponto_de_venda_box"
      "imagem imagem";
  }

  .top-header {
    display: none;
  }

  .image-gallery {
    display: none;
  }

  .hidden-image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .hidden-image-container .image-box img {
    max-width: 300px;
  }
}
