.table-green {
  background: rgb(190, 255, 190);
}

.table-yellow {
  background: rgb(255, 253, 164);
}

.table-red {
  background: rgb(255, 191, 187);
}

.hidden-row {
  padding: 0 !important;
}

.clickable-row {
  cursor: pointer;
}
