.estande-card-container {
  position: relative;
}

.estande-card-container .estande-card-remove {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  background: #dc3545;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  padding-left: 1px;
  cursor: pointer;
}
